import React from 'react';
import { graphql } from 'gatsby';
import styled, { createGlobalStyle } from 'styled-components';
import { injectIntl } from 'gatsby-plugin-react-intl';

import Seo from '@core/Seo';
import RichText from '@core/RichText';

import mq from '@mq';

const TermsOfUsePage = ({
  data: {
    contentfulTermsOfUsePage: { text },
  },
}) => (
  <>
    <Seo pageTitle="Terms of Use" />
    <GlobalStyles />
    <Main>
      <section>
        <Text text={text} />
      </section>
    </Main>
  </>
);

const Main = styled.main`
  flex: 1;
`;

const GlobalStyles = createGlobalStyle`
  body, html, #___gatsby, #gatsby-focus-wrapper {
    height: 100%;
  }

  #gatsby-focus-wrapper {
    display: flex;
    flex-direction: column;
  }
`;

const Text = styled(RichText)`
  grid-column: 1 / -1;
  font: var(--font-xs);

  ${mq.small} {
    font: var(--font-s);
  }

  ${mq.medium} {
    grid-column: 2 /-2;
  }

  ${mq.large} {
    grid-column: 3 /-3;
  }

  ${mq.huge} {
    grid-column: 6 /-6;
  }
`;

export const TermsOfUsePageQuery = graphql`
  query TermsOfUsePageQuery($locale: String) {
    contentfulTermsOfUsePage(node_locale: { eq: $locale }) {
      node_locale
      text {
        raw
      }
    }
  }
`;

export default injectIntl(TermsOfUsePage);
